import { MenuPanel, Page } from "@utdanningsdirektoratet/layout";
import { Notifications } from "@utdanningsdirektoratet/notifications";
import { PopinManager } from "@utdanningsdirektoratet/popin";
import { Outlet, Route, Routes } from "react-router-dom";
import { useContextState } from "./StateProvider";
import routes from "./routes";
import ErrorPage from "./pages/Error/ErrorPage";
import { UdirFooter } from "./components/UdirFooter";
import Personvern from "./pages/Personvern/Personvern";
import NoMatchingPathShowDefault404 from "./pages/NoMatchingPathShowDefault404";
import Hoeringsvar from "./pages/Hoeringsvar/Hoeringsvar";
import Ettersending from "./pages/Ettersending/Ettersending";

const App: React.FC = () => {
  const { state } = useContextState();

  if (state.error) {
    return <ErrorPage error={state.error} />;
  }

  return (
    <Page>
      <Routes>
        <Route
          element={
            <>
              <MenuPanel title="Søknad" external />
              <Outlet />
            </>
          }
        >
          <Route path={routes.ettersending(":externalId")} element={<Ettersending />} />
          <Route path={routes.personvern} element={<Personvern />} />
          <Route path={routes.hoeringsvar(":externalId")} element={<Hoeringsvar />} />
          <Route path="" element={<NoMatchingPathShowDefault404 />} />
        </Route>
      </Routes>
      <PopinManager />
      <Notifications />
      <UdirFooter />
    </Page>
  );
};

export default App;
